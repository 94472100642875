import { OrderStepStateEnum } from "@plazarre/phoenix-ux-order/lib/esm/core/data-types/order-step-state-enum";
import { IDropdownValue } from "@plazarre/phoenix-ux-order/lib/esm/core/interfaces/idropdown";
import { AppContext } from "@plazarre/phoenix-ux-order/lib/esm/hook/use-application-context";
import { OrderButtons } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-buttons";
import { OrderForm } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form";
import { OrderFormDropdown } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-dropdown";
import { OrderFormRow } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-row";
import { EVHeader } from "@plazarre/phoenix-ux-order/lib/esm/ux/organisms/ev-header";
import { IUseFetchOptions, useFetchJSON } from "@plazarre/phoenix.ux.fetch/lib/esm/hook/use-fetch-json";
import { useContext, useEffect, useRef, useState } from "react";
import { appInsights } from "../../App";
import { FormControlMoney } from "../molecules/form-control-money";

const exampleJsx = <></>;

const marketOutlookValues : IDropdownValue[] = [ 
    { name: "--- SELECT RESPONSE ---", index: -1},
    { name: "Very Good (up 25% or more)", index: 0},
    { name: "Good (up 10% to 24%)", index: 1},
    { name: "Neutral (down 9% to up 9%)", index: 2},
    { name: "Poor (down 10% to 24%)", index: 3},
    { name: "Very Poor (down 25% or more)", index: 4},
]

const businessOutlookValues : IDropdownValue[] = [ 
    { name: "--- SELECT RESPONSE ---", index: -1},
    { name: "Significant Growth (sales volume up 25% or more)", index: 0},
    { name: "Growth (sales volume up 10% to 24%)", index: 1},
    { name: "Steady State (sales volume down 9% to up 9%)", index: 2},
    { name: "Wind Down (sales volume down 10% or more)", index: 3},
]

const homeValues : IDropdownValue[] = [ 
    { name: "--- SELECT RESPONSE ---", index: -1},
    { name: "Up (10% or more)", index: 0},
    { name: "Up (5% to 9%)", index: 1},
    { name: "Flat (down 4% to up 4%)", index: 2},
    { name: "Down (5% to 9%)", index: 3},
    { name: "Down (10% or more)", index: 4},
]

const colLg = 6;

interface IOutlookSurvey {
    advisorEmail: string,
    surveyYear: number,
    marketOutlook: number,
    businessOutlook: number,
    homeValueOutlook: number,
    salesVolumeGoal: number,
    averageSalesPrice: number,
    averageCommissionPercent: number,
    gci: number
}

interface IAdvisorSales {
    advisorId: string,
    advisorStartDate: Date,
    averageSalesCommission: number,
    averageSalesPrice: number
    brokerageCommissionDollars: number, 
    cumulativeSalesGCIAdjusted: number,
    cumulativeSalesVolumeAdjusted: number,
    cumulativeSalesSidesAdjusted: number,
    masterScheduleTierLevel: number,
    name: string, 
    performanceEndDate: Date,
    performanceStartDate: Date,
    shop: string, 
    splitPercentage: number,
}

// If the date is past October 1, then the survey is for the following year.
// Otherwise, the survey is for the current year.
const month = new Date().getMonth();
const year = new Date().getFullYear();
const OCTOBER = 9; // Months are zero-based
console.log('month, year', month, year);
const isNextYear = month >= OCTOBER;
const THIS_YEAR = isNextYear ? year : year-1;
const NEXT_YEAR = (THIS_YEAR + 1);

export const BusinessPlanningSurveyPage : React.FC = (props) => {
    const [marketOutlook, setMarketOutlook] = useState(-1);
    const [businessOutlook, setBusinessOutlook] = useState(-1);
    const [homeValueOutlook, setHomeValueOutlook] = useState(-1);
    const [salesVolumeGoal, setSalesVolumeGoal] = useState('');
    const [averageSalesPrice, setAverageSalesPrice] = useState('');
    const [averageCommissionPercent, setAverageCommissionPercent] = useState('');
    const [gci, setGCI] = useState('');
    const [isAnswered, setIsAnswered] = useState(false);
    const [shouldShowSubmitWaiting, setShouldShowSubmitWaiting] = useState(false);

    const [gciNote, setGCINote] = useState('');
    const [averageCommissionPercentNote, setAverageCommissionPercentNote] = useState('');
    const [averageSalesPriceNote, setAverageSalesPriceNote] = useState('');
    const [salesVolumeNote, setSalesVolumeNote] = useState('');

    const outlookRef = useRef(null);
    const appContext = useContext(AppContext);

    const idToken = localStorage.getItem('user_token') ?? '';
    //const survey = { jsonSurvey: 'abc '};

    const optionsInitializeOnly : IUseFetchOptions = {
        jwtToken: idToken,
        method: 'POST',
        shouldInitializeOnly: true,
    };

    const optionsGetFetch : IUseFetchOptions = {
        jwtToken: idToken,
        shouldInitializeOnly: true,
        method: 'GET',
        shouldUseCache: false,
        timeoutMilliseconds: 10000 // (*) TODO: This isn't ideal. Why is sales-calendar taking so long? (12/22/2022 - pel)
    };

    const [, , doPost] = useFetchJSON<any>(`/api/survey/`, optionsInitializeOnly);
    const [ salesDataResponse, , doSalesGet ] = useFetchJSON<IAdvisorSales>(`/api/advisor/sales-calendar/`, optionsGetFetch);

    useEffect(() => {
        if (!appContext.brokerMintId || !doSalesGet) 
            return;

        // eslint-disable-next-line eqeqeq
        if (salesDataResponse.hasData && salesDataResponse.data?.advisorId == appContext.brokerMintId) {
            console.warn(`salesData already loaded for ${appContext.brokerMintId}`);
            return;
        }

        const getSalesData = async () => {
            await doSalesGet({ urlParams: appContext.brokerMintId });
        }

        getSalesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appContext.brokerMintId, salesDataResponse.hasData]);


    useEffect(() => {
        if (!salesDataResponse.hasData)
            return;

        console.log('sales data', salesDataResponse);
    }, [salesDataResponse]);

    const setOrderFields = (step: number, state: OrderStepStateEnum) => {
        console.log('step', step, state);
        console.log('currentsettings', appContext);
        const survey : IOutlookSurvey = {
            advisorEmail: appContext.userLoginEmail,
            surveyYear: NEXT_YEAR,
            marketOutlook,
            businessOutlook,
            homeValueOutlook,
            salesVolumeGoal : Number.parseFloat(salesVolumeGoal),
            averageSalesPrice : Number.parseFloat(averageSalesPrice),
            averageCommissionPercent : Number.parseFloat(averageCommissionPercent),
            gci : Number.parseFloat(gci)
        };

        console.log('survey', JSON.stringify(survey));
        setShouldShowSubmitWaiting(true);
        appInsights.trackTrace({message: JSON.stringify(survey)});
        console.log('user', appContext);
        doPost({ urlParams: `paul.lazarre@engelvoelkers.com/${NEXT_YEAR}`, body: survey })
            .then(() => setIsAnswered(true));
        
    }

    const isSubmitDisabled = marketOutlook < 0 || businessOutlook < 0 || homeValueOutlook < 0;
    const buttonsJsx = (
        <OrderButtons
            submitLabel="Submit"
            submitDisabled={isSubmitDisabled}
            submitWaitingIcon={shouldShowSubmitWaiting}
            skipLabel=""
            backLabel=""
            SetOrderFields={setOrderFields}
        />
    );

    if (isAnswered) {
        return (
            <>
            <EVHeader title={`${NEXT_YEAR} SURVEY`} />
            <div>
                Thank you for answering our {NEXT_YEAR} business outlook survey!
            </div>
            </>
        );
    }

    let forSalesVolumeString = '';
    let gciCumulativeString = '';
    let salesCommissionString = '';
    let salesPriceString = '';
    if (salesDataResponse.hasData) {
        
        const cumulativeGCIString = salesDataResponse.data?.cumulativeSalesGCIAdjusted.toLocaleString('en-US', {maximumFractionDigits:0});
        const cumulativeSalesString = salesDataResponse.data?.cumulativeSalesVolumeAdjusted.toLocaleString('en-US', {maximumFractionDigits:0});
        const commissionPercentString = ((salesDataResponse.data?.averageSalesCommission ?? 0) * 100).toLocaleString('en-US', {maximumFractionDigits:2});
        const averagePriceString = salesDataResponse.data?.averageSalesPrice.toLocaleString('en-US', {maximumFractionDigits:0});
        forSalesVolumeString = `Your for-sale volume (without rentals and referrals) in ${THIS_YEAR} was approximately $${cumulativeSalesString}.`;
        gciCumulativeString = `Your total ${THIS_YEAR} GCI (including rentals and referrals) was $${cumulativeGCIString}.`;
        salesCommissionString = `Your ${THIS_YEAR} average commission (without rentals and referrals) was ${commissionPercentString}%.`;
        salesPriceString = `Your average sales price (without rentals and referrals) in ${THIS_YEAR} was approximately $${averagePriceString}.`;
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement>, setStateValue: React.Dispatch<React.SetStateAction<string>>) => {
        if (!salesDataResponse.hasData)
            return;
        
        const name = e.currentTarget.name ?? '';
        const nameToValueMap = [{ fieldName: 'gci', valueName: 'cumulativeSalesGCIAdjusted', isPrecent: false },
            {fieldName: 'average_commission_percent', valueName: 'averageSalesCommission', isPercent: true },
            {fieldName: 'average_sales_price', valueName: 'averageSalesPrice'},
            {fieldName: 'sales_volume', valueName: 'cumulativeSalesVolumeAdjusted'}
         ];

        const map = nameToValueMap.find(e => e.fieldName === name);
        if (!map)
            throw new Error(`${name} not found in nameToValueMap`);

        const { valueName, isPercent } = map;
        const valueString = e.currentTarget.value.replace(/,/g, '');
        const valueNumeric = Number.parseFloat(valueString);
        console.log('valueNumeric', valueNumeric);
        const data = salesDataResponse.data as any;
        let valueData : number = data[valueName];
        if (isPercent)
            valueData = valueData * 100;

        console.log('valueData', valueData);
        if (valueNumeric > 0 && valueData) {
            const percent = valueNumeric / valueData;
            let note : string;
            if (percent < 1) {
                note = `Down ${((1-percent)*100).toLocaleString('en-US', {maximumFractionDigits:0})}% from ${THIS_YEAR}`;
            } else {
                const increasePercent = (percent - 1) * 100;
                if (Math.round(increasePercent) === 0) 
                    note = `Flat from ${THIS_YEAR}`;
                else 
                    note = `Up ${increasePercent.toLocaleString('en-US', {maximumFractionDigits:0})}% from ${THIS_YEAR}`;
            }

            setStateValue(note);
            console.log('note', note);
        }
    }

    return (
        <>
            <EVHeader title={`${NEXT_YEAR} SURVEY`} />
            <OrderForm 
                id="survey"
                name="survey" 
                buttonsJsx={buttonsJsx} 
                exampleJsx={exampleJsx} 
                SetOrderFields={setOrderFields}
                >
                <OrderFormDropdown
                    variant="secondary"
                    label={`1. What is your outlook for your ${NEXT_YEAR} local housing market`}
                    value={marketOutlook}
                    dropdownValues={marketOutlookValues}
                    colLg={colLg}
                    middleColLg={colLg}
                    setValue={setMarketOutlook}
                    ref={outlookRef}
                />

                <OrderFormDropdown
                    variant="secondary"
                    label={`2. What is your goal for your business in ${NEXT_YEAR}`}
                    value={businessOutlook}
                    dropdownValues={businessOutlookValues}
                    colLg={colLg}
                    middleColLg={colLg}
                    setValue={setBusinessOutlook}
                    ref={outlookRef}
                />

                <OrderFormDropdown
                    variant="secondary"
                    label={`3. In your local market, how much do you believe homes will appreciate/depreciate in ${NEXT_YEAR}`}
                    value={homeValueOutlook}
                    dropdownValues={homeValues}
                    colLg={colLg}
                    middleColLg={colLg}
                    setValue={setHomeValueOutlook}
                    ref={outlookRef}
                />

            <OrderFormRow colLg={colLg-1} header={`4. Enter your sales volume goal for ${NEXT_YEAR}`} value="">
                <FormControlMoney
                    name="sales_volume"
                    afterInputDisplay={salesVolumeNote}
                    onBlur={(e) => onBlur(e, setSalesVolumeNote)}
                    value={salesVolumeGoal}
                    setValue={setSalesVolumeGoal}
                    validationMessage={`Please enter your sales volume goal for ${NEXT_YEAR}.`}
                    maxLength={11}
                    width="10rem"
                />
                {forSalesVolumeString}
            </OrderFormRow>

            <OrderFormRow colLg={colLg-1} header={`5. Enter your average sales price goal for ${NEXT_YEAR}`} value="">
                <FormControlMoney
                    name="average_sales_price"
                    afterInputDisplay={averageSalesPriceNote}
                    onBlur={(e) => onBlur(e, setAverageSalesPriceNote)}
                    value={averageSalesPrice}
                    setValue={setAverageSalesPrice}
                    validationMessage={`Please enter your average sales price goal for ${NEXT_YEAR}.`}
                    width="8rem"
                />
                {salesPriceString}
            </OrderFormRow>

            <OrderFormRow colLg={colLg-1} header={`6. Enter your average commission goal for ${NEXT_YEAR}`} value="">
                <FormControlMoney
                    name="average_commission_percent"
                    afterInputDisplay={averageCommissionPercentNote}
                    onBlur={(e) => onBlur(e, setAverageCommissionPercentNote)}
                    currency="%"
                    digits={2}
                    value={averageCommissionPercent}
                    setValue={setAverageCommissionPercent}
                    validationMessage={`Please enter your average commission goal for ${NEXT_YEAR}.`}
                    width="8rem"
                />
                {salesCommissionString}
            </OrderFormRow>

            <OrderFormRow colLg={colLg-1} header={`7. Enter your GCI goal for ${NEXT_YEAR}`} value="">
                <FormControlMoney
                    afterInputDisplay={gciNote}
                    onBlur={(e) => onBlur(e, setGCINote)}
                    name="gci"
                    value={gci}
                    setValue={setGCI}
                    validationMessage={`Please enter your GCI goal for ${NEXT_YEAR}.`}
                    width="8rem"
                />
                {gciCumulativeString}
            </OrderFormRow>
            
        </OrderForm>
        </>
    );
}